// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".excalidraw .App-toolbar.zen-mode .ToolIcon__keybinding,\n.excalidraw .App-toolbar.zen-mode .HintViewer {\n  display: none;\n}\n.excalidraw .App-toolbar__divider {\n  width: 1px;\n  height: 1.5rem;\n  align-self: center;\n  background-color: var(--default-border-color);\n  margin: 0 0.5rem;\n}", "",{"version":3,"sources":["webpack://./src/components/Toolbar.scss"],"names":[],"mappings":"AAMM;;EAEE,aAAA;AAAR;AAII;EACE,UAAA;EACA,cAAA;EACA,kBAAA;EACA,6CAAA;EACA,gBAAA;AAFN","sourcesContent":["@import \"open-color/open-color.scss\";\n@import \"../css/variables.module\";\n\n.excalidraw {\n  .App-toolbar {\n    &.zen-mode {\n      .ToolIcon__keybinding,\n      .HintViewer {\n        display: none;\n      }\n    }\n\n    &__divider {\n      width: 1px;\n      height: 1.5rem;\n      align-self: center;\n      background-color: var(--default-border-color);\n      margin: 0 0.5rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"themeFilter": "invert(93%) hue-rotate(180deg)",
	"rightSidebarWidth": "302px"
};
export default ___CSS_LOADER_EXPORT___;
