// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".excalidraw .confirm-dialog-buttons {\n  display: flex;\n  column-gap: 0.5rem;\n  justify-content: flex-end;\n}", "",{"version":3,"sources":["webpack://./src/components/ConfirmDialog.scss"],"names":[],"mappings":"AAII;EACE,aAAA;EACA,kBAAA;EACA,yBAAA;AAEN","sourcesContent":["@import \"../css/variables.module\";\n\n.excalidraw {\n  .confirm-dialog {\n    &-buttons {\n      display: flex;\n      column-gap: 0.5rem;\n      justify-content: flex-end;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"themeFilter": "invert(93%) hue-rotate(180deg)",
	"rightSidebarWidth": "302px"
};
export default ___CSS_LOADER_EXPORT___;
