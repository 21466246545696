// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".excalidraw .Dialog {\n  -webkit-user-select: text;\n          user-select: text;\n  cursor: auto;\n}\n.excalidraw .Dialog__title {\n  margin: 0;\n  text-align: left;\n  font-size: 1.25rem;\n  border-bottom: 1px solid var(--dialog-border-color);\n  padding: 0 0 0.75rem;\n  margin-bottom: 1.5rem;\n}", "",{"version":3,"sources":["webpack://./src/components/Dialog.scss"],"names":[],"mappings":"AAGE;EACE,yBAAA;UAAA,iBAAA;EACA,YAAA;AAGJ;AAAE;EACE,SAAA;EACA,gBAAA;EACA,kBAAA;EACA,mDAAA;EACA,oBAAA;EACA,qBAAA;AAEJ","sourcesContent":["@import \"../css/variables.module\";\n\n.excalidraw {\n  .Dialog {\n    user-select: text;\n    cursor: auto;\n  }\n\n  .Dialog__title {\n    margin: 0;\n    text-align: left;\n    font-size: 1.25rem;\n    border-bottom: 1px solid var(--dialog-border-color);\n    padding: 0 0 0.75rem;\n    margin-bottom: 1.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"themeFilter": "invert(93%) hue-rotate(180deg)",
	"rightSidebarWidth": "302px"
};
export default ___CSS_LOADER_EXPORT___;
